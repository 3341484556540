.fullPartyName {
  display: none;

  @media only screen and (min-width: 650px) {
    display: block;
  }
}

.shortPartyName {
  display: block;

  @media only screen and (min-width: 650px) {
    display: none;
  }
}