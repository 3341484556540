.inputWrapper {
  display: block;
  width: 100%;
  margin-top: 20px;

  @media only screen and (min-width: 500px) {
    width: 80%;
    margin-top: 25px;
  }

  @media only screen and (min-width: 750px) {
    width: 60%;
    margin-top: 30px;
  }

  @media only screen and (min-width: 1000px) {
    width: 40%;
    margin-top: 35px;
  }
}