.questionWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 40px;

    width: 100%;
    min-height: 240px;
    font-size: 25px;

    @media only screen and (min-width: 400px) {
        min-height: 180px;
        font-size: 30px;
    }

    @media only screen and (min-width: 800px) {
        min-height: 180px;
        font-size: 35px;
    }

    @media only screen and (min-width: 1200px) {
        width: 75%;
        min-height: 180px;
        font-size: 35px;
    }

    @media only screen and (min-width: 1400px) {
        width: 50%;
        min-height: 180px;
        font-size: 40px;
    }
}

.buttonWrapper {
    width: 100%;
    height: 40px;
    text-align: center;
    margin: 5px;
    
    @media only screen and (min-width: 350px) {
        width: 350px;
        margin: 10px;
    }
}