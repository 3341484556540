.bannerImage {
  float: left;
  width: 250px;
  height: 50px;
  margin-top: 8px;
}

.navigationMenu {
  height: 64px;
  line-height: 64px;
  background: #001529;

  @media only screen and (min-width: 1200px) {
    padding-left: 50px;
  }
}

.menu {
  display: none;

  @media only screen and (min-width: 1200px) {
    display: inline-flex;
    line-height: 64px;
    margin-left: 30px;
    list-style: none;
  }
}

.menuItem {
  margin-right: 30px;
  text-decoration: none;
}

.link {
  color: rgba(255, 255, 255, 0.65);
}

.link:hover {
  color: white;
}

.menuItem .anticon {
  margin-right: 10px;
}

.mobileMenu {
  display: inline-flex;
  justify-content: flex-end;
  float: right;

  @media only screen and (min-width: 1200px) {
    display: none;
  }
}

.mobileIcon {
  color: white !important;
  font-size: 30px;
  font-weight: bolder;
  margin: 16px 20px 16px 0;
}

.mobileMenuItem {
  width: 100%;
  display: inline-flex;
  font-size: 20px;
  justify-content: center;
  margin: 5px 0 5px 0;
}

.mobileMenuLink {
  color: rgba(255, 255, 255, 0.8);
}

.mobileMenuLink .anticon {
  margin-right: 15px;
}

.mobileNavigationModal .ant-modal-content {
  background: #001529 !important;
}