.inputs {
  float: left;
  margin-top: 25px;
  width: 100%;

  @media only screen and (min-width: 1200px) {
    width: 50%;
  }
}

.responsiveGraphic {
  display: flex;
  justify-content: center;
  width: 100%;

  @media only screen and (min-width: 1200px) {
    float: right;
    width: 50%;
  }
}

.graphicWrapper {
  width: 100%;

  @media only screen and (min-width: 800px) {
    width: 75%;
  }

  @media only screen and (min-width: 1200px) {
    width: 60%;
  }
}