.adjustingCenteredContent {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0.6;
  flex-wrap: wrap;
  margin-top: 40px;
  width: 100%;

  @media only screen and (min-width: 1000px) {
    width: 80%;
  }

  @media only screen and (min-width: 1400px) {
    width: 60%;
  }
}

.ideologyWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin-bottom: 40px;
  text-align: center;
  line-height: 1.2;
}

.ideologyName {
  display: block;
  width: 80%;
  font-size: 50px;
  margin-bottom: 5px;
}

.ideologyDesc {
  display: block;
  width: 100%;
  font-size: 16px;
}

.compareResult {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}

.compareResultText {
  font-size: 20px;
  color: #1890ff;
  font-weight: bold;
  cursor: pointer;
}