.centeredContent {
  display: flex;
  justify-content: center;
}

.dropdown {
  margin-bottom: 15px;
}

.header {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.axisComparison {
  width: 100%;
  display: inline-flex;
  margin: 10px;
}

.axisComparisonText {
  display: flex;
  font-size: 18px;
  align-items: center;
  margin-left: 10px;
}