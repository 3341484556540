.ant-table {
  overflow: scroll;

  @media only screen and (min-width: 625px) {
    overflow: visible;
  }
}

.rotationSuggestion {
  display: none;

  @media only screen and (max-width: 660px) {
    display: block;
    margin-bottom: 20px;
  }
}