.centeredLoading {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.loadingParent {
  display: grid;
}

.loadingCircle {
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
}

.loadingImage {
  grid-column: 1;
  grid-row: 1;
  z-index: 2;
  margin: 5px 0 0 15px;
}